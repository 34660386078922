import React from 'react';
import styled from 'styled-components';

// ALL Basic components have a top margin of 7px

export const Button = styled.button`
  margin-top: 7px;
  padding: 12px 60px;
  border-radius: 100px;
  font-size: ${props => props.theme.fontSizes.label};
  background: ${props => props.theme.colors.green};
  color: white;
`;

export const Link = styled.a`
  margin-top: 7px;
  text-align: center;
  font-size: ${props => props.theme.fontSizes.label};
  color: ${props => props.theme.colors.blue};
`;

export const BlankLink = styled(Link).attrs({
  target: '_blank',
  rel: 'noopener norefferer',
})``;

export const Image = styled.img`
  margin-top: 7px;
  width: 10vw;
  height: 10vh;
`;

export const Text = styled.div`
  margin-top: 7px;
  font-size: ${props => props.theme.fontSizes.label};
  display: flex;
  justify-content: center;

  ${props => props.styles};
`;

// if you export a component like this, you won't be able to style it afterwards
export const NoRefLink = ({ label, link }) => (
  <Link
    href={link}
    target="_blank"
    rel="noopener norefferer"
  >
    {label}
  </Link>
);

/* if you leave a styles prop and add it to the initial config
(see line 32 of this file)
then you can pass that prop and style it from any place in your app */
export const StyledText = ({ label, ...props }) => (
  <Text {...props}>
    {label}
  </Text>
);

const Basic = {
  Button,
  Link,
  NoRefLink,
  Image,
  Text,
};

export default Basic;
