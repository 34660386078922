const theme = {
  colors: {
    black: 'hsl(0, 0%, 10%)',
    lightBlack: 'hsl(0, 0%, 55%)',
    gray: 'hsl(0, 0%, 75%)',
    lightGray: 'hsl(0, 0%, 90%)',
    blue: 'hsl(215, 62%, 51%)',
    green: 'hsl(75, 60%, 47%)',
    red: 'hsl(0, 85%, 55%)',
  },
  fontSizes: {
    title: '50px',
    label: '16px',
  },
};

export default theme;
