import { css } from 'styled-components';

const animate = (
  items,
  time = 2000,
  delay = 0,
  stagger = 100,
  axis = 'Y',
) => css`
  opacity: 0;
  transform: translate${axis}(${axis === 'Y' ? '50%' : '-50px'});
  animation: enter ${time}ms cubic-bezier(0.075, 0.820, 0.165, 1.000) forwards;
  @keyframes enter {
    100% { 
      opacity: 1; 
      transform: translateY(0);
    }
  }
  ${[...Array(items)].map((item, i) => css`
    &:nth-child(${axis === 'Y' ? (i + 1) : (items - i)}) {
      animation-delay: ${i * stagger + delay}ms;
    }
  `)};
`;

export default animate;
