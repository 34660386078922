import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// enable whichever font u want to use and call it in render
// import { AvenirNext } from './components/Fonts';
import { ProductSans } from './components/Fonts';
// import { BwGlennSans } from './components/Fonts';
// import { GESSTwo } from './components/Fonts'; // usually used for Arabic
import * as serviceWorker from './serviceWorker';

ReactDOM.render((
  <Fragment>
    <ProductSans />
    <App />
  </Fragment>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
