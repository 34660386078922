import { FontFaces } from '../utils/FontFace';

import BwGlennSans400 from '../assets/fonts/BwGlennSans/BwGlennSans400.woff';
import BwGlennSans700 from '../assets/fonts/BwGlennSans/BwGlennSans700.woff';
import BwGlennSans900 from '../assets/fonts/BwGlennSans/BwGlennSans900.woff';

import GESSTwo400 from '../assets/fonts/GESSTwo/GESSTwo400.woff';
import GESSTwo700 from '../assets/fonts/GESSTwo/GESSTwo700.woff';
import GESSTwo900 from '../assets/fonts/GESSTwo/GESSTwo900.woff';

import AvenirNext100 from '../assets/fonts/AvenirNext/AvenirNext100.woff';
import AvenirNext200 from '../assets/fonts/AvenirNext/AvenirNext200.woff';
import AvenirNext300 from '../assets/fonts/AvenirNext/AvenirNext300.woff';
import AvenirNext400 from '../assets/fonts/AvenirNext/AvenirNext400.woff';
import AvenirNext500 from '../assets/fonts/AvenirNext/AvenirNext500.woff';
import AvenirNext600 from '../assets/fonts/AvenirNext/AvenirNext600.woff';
import AvenirNext700 from '../assets/fonts/AvenirNext/AvenirNext700.woff';
import AvenirNext900 from '../assets/fonts/AvenirNext/AvenirNext900.woff';

import ProductSansTTF from '../assets/fonts/ProductSans/ProductSans.ttf';

export const BwGlennSans = FontFaces.create(
  [
    { url: BwGlennSans400, weight: 400 },
    { url: BwGlennSans700, weight: 700 },
    { url: BwGlennSans900, weight: 900 },
  ],
  { name: 'BwGlennSans' },
);

export const GESSTwo = FontFaces.create(
  [
    { url: GESSTwo400, weight: 400 },
    { url: GESSTwo700, weight: 700 },
    { url: GESSTwo900, weight: 900 },
  ],
  { name: 'GESSTwo' },
);

export const AvenirNext = FontFaces.create(
  [
    { url: AvenirNext100, weight: 100 },
    { url: AvenirNext200, weight: 200 },
    { url: AvenirNext300, weight: 300 },
    { url: AvenirNext400, weight: 400 },
    { url: AvenirNext500, weight: 500 },
    { url: AvenirNext600, weight: 600 },
    { url: AvenirNext700, weight: 700 },
    { url: AvenirNext900, weight: 900 },
  ],
  { name: 'AvenirNext' },
);

export const ProductSans = FontFaces.create(
  [
    { url: ProductSansTTF, weight: 100 },
    { url: ProductSansTTF, weight: 200 },
    { url: ProductSansTTF, weight: 300 },
    { url: ProductSansTTF, weight: 400 },
    { url: ProductSansTTF, weight: 500 },
    { url: ProductSansTTF, weight: 600 },
    { url: ProductSansTTF, weight: 700 },
    { url: ProductSansTTF, weight: 900 },
  ],
  { name: 'ProductSans' },
);
