import React, { Component } from 'react';
import styled, {
  createGlobalStyle,
  ThemeProvider,
} from 'styled-components';
import reset from 'styled-reset-advanced';

import { BlankLink } from './components/Basic';

import theme from './utils/theme';
import animate from './utils/animate';

const GlobalStyle = createGlobalStyle`
  ${reset};
  html, body {
    font-family: 'Product Sans', sans-serif;
  };
`;

const Wrapper = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > * {
    ${animate(5, 2000, 0, 200)}
  }
`;

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Wrapper>
          <GlobalStyle />
          <BlankLink
            href="https://github.com/ivanprtljaga"
          >
            Github
          </BlankLink>
        </Wrapper>
      </ThemeProvider>
    );
  }
}
